import {defineStore} from 'pinia'
import StockService from '../services/StockService'

export const useStockStore = defineStore('stock', {
    state: () => ({
        warehouses: [],
        warehouse: {},

        loading: {
            warehouses: false,
            send: false
        }
    }),

    getters: {
        getWarehouses: (state) => state.warehouses,
        getWarehouseDetails: (state) => state.warehouse,
        isWarehousesLoading: (state) => state.loading.warehouses,
        isSending: (state) => state.loading.send,
    },

    actions: {
        async fetchWarehouses() {
            this.loading.warehouses = true

            try {
                const res = await StockService.fetchWarehouses()
                this.warehouses = res.data.data
            } catch (e) {

            } finally {
                this.loading.warehouses = false
            }
        },

        async fetchWarehouseDetails(id) {
            this.loading.warehouse = true

            try {
                const res = await StockService.fetchWarehouseDetails(id)
                this.warehouse = res.data.data
            } catch (e) {

            } finally {
                this.loading.warehouse = false
            }
        },

        async createTransfer(data) {
            this.loading.sending = true

            try {
                const res = await StockService.createTransfer(data)

                if(res.status === 200){
                    $('#AdminStockWarehouseTransferCreateModal').fadeOut(400)
                }
            } catch (e) {

            } finally {
                this.loading.sending = false
            }
        },

    },
})
