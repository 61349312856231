import RequestProvider from '../providers/ApiRequestProvider'

const URL_PART = 'dashboard'

export default {
    fetchDashboard( filters ) {
        return RequestProvider._post( `${URL_PART}`, filters )
    },

}
