import ApiRequestProvider from '../providers/ApiRequestProvider'

const addItems = (formData, items) => {
    items.forEach((item, i) => {
        formData.append(`items[${i}][item_id]`, item.item_id)
        formData.append(`items[${i}][amount]`, item.amount)
    })
}

const URL_PART = 'orders'

export default {
    async fetchOrder(id) {
        return await ApiRequestProvider._get(`${URL_PART}/${id}`)
    },

    async fetchSources() {
        return await ApiRequestProvider._get(`${URL_PART}/sources`)
    },

    async fetchOrdersIncome( interval ) {
        return ApiRequestProvider._post( `${URL_PART}/income`, { interval })
    },

    async fetchOrdersSources( interval ) {
        return ApiRequestProvider._post( `${URL_PART}/ordersCountBySource`,{ interval } )
    },

    async createOrder(order) {
        const formData = new FormData
        formData.append(`user_id`, order.user_id ?? '')
        formData.append(`user_name`, order.user_name ?? '')
        formData.append(`user_phone`, order.user_phone ?? '')
        formData.append(`user_email`, order.user_email ?? '')
        formData.append(`payment_method_id`, order.payment_method_id)
        formData.append(`desc`, order.desc ?? '')
        formData.append(`area`, order.area ?? '')
        formData.append(`np_area_ref`, order.np_area_ref ?? '')
        formData.append(`city`, order.city ?? '')
        formData.append(`np_city_ref`, order.np_city_ref ?? '')
        formData.append(`warehouse`, order.warehouse ?? '')
        formData.append(`np_warehouse_ref`, order.np_warehouse_ref ?? '')
        formData.append(`source_id`, order.source_id ?? '')
        addItems(formData, order.items)

        return await ApiRequestProvider._post('orders', formData)
    },

    async updateOrder(order) {
        const formData = new FormData
        formData.append(`_method`, 'PUT')
        formData.append(`user_id`, order.user_id ?? '')
        formData.append(`user_name`, order.user_name ?? '')
        formData.append(`user_phone`, order.user_phone ?? '')
        formData.append(`user_email`, order.user_email ?? '')
        formData.append(`payment_method_id`, order.payment_method_id)
        formData.append(`desc`, order.desc ?? '')
        formData.append(`area`, order.area ?? '')
        formData.append(`city`, order.city ?? '')
        formData.append(`warehouse`, order.warehouse ?? '')
        formData.append(`source_id`, order.source_id ?? '')
        addItems(formData, order.items)

        return await ApiRequestProvider._post(`orders/${order.id}`, formData)
    },


}
