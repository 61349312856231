import ApiRequestProvider from '../providers/ApiRequestProvider'

const addItems = (formData, items) => {
    items.forEach(item => {
        formData.append(`items[]`, item)
    })
}

export default {
    async fetchOffer(id){
        return await ApiRequestProvider._get(`offers/${id}`)
    },

    async createOffer(offer){
        const formData = new FormData

        formData.append(`title`, offer.title ?? '')
        formData.append(`subtitle`, offer.subtitle ?? '')
        formData.append(`desc`, offer.desc ?? '')
        formData.append(`button_text`, offer.button_text ?? '')
        formData.append(`button_url`, offer.button_url ?? '')
        formData.append(`dark`, offer.dark ? 1 : 0)
        formData.append(`image`, offer.image)
        formData.append(`discount`, offer.discount ?? '')
        addItems(formData, offer.items)

        return await ApiRequestProvider._post(
            'offers',
            formData,
            {'Content-Type': 'multipart/form-data'}
        )
    },

    async updateOffer(offer){
        const formData = new FormData

        formData.append(`_method`, 'PUT')
        formData.append(`title`, offer.title ?? '')
        formData.append(`subtitle`, offer.subtitle ?? '')
        formData.append(`desc`, offer.desc ?? '')
        formData.append(`button_text`, offer.button_text ?? '')
        formData.append(`button_url`, offer.button_url ?? '')
        formData.append(`dark`, offer.dark ? 1 : 0)
        formData.append(`image`, offer.image)
        formData.append(`discount`, offer.discount ?? '')
        addItems(formData, offer.items)

        return await ApiRequestProvider._post(
            `offers/${offer.id}`,
            formData,
            {'Content-Type': 'multipart/form-data'}
        )
    },

}
