import ApiRequestProvider from '../providers/ApiRequestProvider'

const PREFIX = 'stocks'

const addItems = (formData, items) => {
    items.forEach((item, i) => {
        formData.append(`items[${i}][item_id]`, item.item_id)
        formData.append(`items[${i}][amount]`, item.amount)
    })
}

export default {
    async fetchWarehouses(){
        return await ApiRequestProvider._get(`warehouses`)
    },

    async fetchWarehouseDetails(id){
        return await ApiRequestProvider._get(`warehouses/${id}`)
    },

    async createTransfer(data) {
        const formData = new FormData

        formData.append(`warehouse_from_id`, data.from)
        formData.append(`warehouse_to_id`, data.to)
        addItems(formData, data.items)

        return await ApiRequestProvider._post('orders', formData)
    },


}
