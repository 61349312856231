import {defineStore} from 'pinia'

import CartService from '../services/CartService'

export const useCartStore = defineStore('cart', {
    state: () => ({
        items: [],
        price: 0,
        priceDisplay: '0',
    }),

    getters: {
        getCartItems: (state) => state.items,
        getCartPrice: (state) => state.price,
        getCartPriceDisplay: (state) => state.priceDisplay,
    },

    actions: {
        handle(res) {
            if (res.data.items !== undefined) {
                this.items = res.data.items
                this.price = res.data.price
                this.priceDisplay = res.data.price_display
            }
        },

        async fetchCart() {
            try {
                const res = await CartService.fetchCart()
                this.handle(res.data)
            } catch (e) {

            }
        },

        async add(id) {
            try {
                const res = await CartService.add(id)
                this.handle(res.data)
            } catch (e) {

            }
        },

        async increment(id) {
            try {
                const res = await CartService.increment(id)
                this.handle(res.data)
            } catch (e) {

            }
        },

        async decrement(id) {
            try {
                const res = await CartService.decrement(id)
                this.handle(res.data)
            } catch (e) {

            }
        },

        async remove(id) {
            try {
                const res = await CartService.remove(id)
                this.handle(res.data)
            } catch (e) {

            }
        },

        async clear() {
            try {
                const res = await CartService.clear()
                this.handle(res.data)
            } catch (e) {

            }
        },


    },
})
