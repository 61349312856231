<template>
    <img v-show="loading" class="w-16 h-auto aspect-square" src="../assets/images/spinning-dots.svg" alt=""/>
</template>
<script>
export default {
    props: {
        loading: {type: Boolean, default: false},
        size: {type: Number, default: 30},
    }
}
</script>
