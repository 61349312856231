<template>
    <div id="AdminOrdersCreateForm">
        <div class="grid grid-cols-2 gap-6 py-4">
            <div>
                <h3 class="text-lg font-semibold">Товари</h3>
                <ul class="flex flex-col gap-2">
                    <li v-for="(item, i) in data.items" :key="i" class="grid grid-cols-8 items-center gap-2">
                        <div class="p-1">{{ i + 1 }}</div>

                        <select @change="selectItem(i, item.item_id)"
                                v-model.number="item.item_id"
                                class="col-span-2 input-normal input-sm">
                            <option value="" selected disabled>- вибрати -</option>
                            <option v-for="_item in items" :key="_item.id" :value="_item.id">{{ _item.name }}
                            </option>
                        </select>

                        <div class="col-span-2 flex flex-row justify-between items-center gap-2">
                            <div v-if="!isMinAmountReached(i)" @click="decrementItem(i)" class="p-1">
                                <i class="mdi mdi-minus"></i>
                            </div>
                            <div v-else class="p-1 text-gray-400"><i class="mdi mdi-minus"></i></div>

                            <input v-model.number="item.amount"
                                   @input="[handleItemAmount(i), recountItem(i)]"
                                   type="number"
                                   class="input-normal input-sm appearance-none"/>

                            <div v-if="!isMaxAmountReached(i)" @click="incrementItem(i)" class="p-1">
                                <i class="mdi mdi-plus"></i>
                            </div>
                            <div v-else class="p-1 text-gray-400"><i class="mdi mdi-plus"></i></div>
                        </div>

                        <input v-model.number="item.item_price" type="text" readonly/>

                        <input v-model.number="item.total_price" type="text" readonly/>

                        <div @click="deleteItem(i)" class="p-1 text-center text-red-400">
                            <i class="mdi mdi-trash-can-outline"></i>
                        </div>
                    </li>
                </ul>
                <div @click="addItem()"
                     class="py-2 mt-4 border-dashed border-2 border-gray-300 rounded-md text-center cursor-pointer">
                    +
                </div>
            </div>

            <ul class="grid grid-cols-2 gap-4">
                <li class="col-span-full">
                    <div class="flex flex-col gap-2">
                        <label for="AdminOrdersCreateUser">Користувач</label>
                        <select v-model="data.user_id" @change="handleUserChange" class="input-normal input-md">
                            <option value="" selected>- вибрати -</option>
                            <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }}</option>
                        </select>
                    </div>
                </li>

                <li v-if="!data.user_id">
                    <div class="flex flex-col gap-2">
                        <label for="AdminOrdersCreateUserName">Ім'я</label>
                        <input v-model="data.user_name" id="AdminOrdersCreateUserName" type="text"
                               class="input-normal input-md"/>
                    </div>
                </li>

                <li v-if="!data.user_id">
                    <div class="flex flex-col gap-2">
                        <label for="AdminOrdersCreateUserPhone">Телефон</label>
                        <input v-maska data-maska="+38 (###) ### ## ##"
                               v-model="data.user_phone" id="AdminOrdersCreateUserEmail" type="text"
                               class="input-normal input-md"
                        />
                    </div>
                </li>

                <li v-if="!data.user_id" class="col-span-full">
                    <div class="flex flex-col gap-2">
                        <label for="AdminOrdersCreateUserEmail">Email</label>
                        <input v-model="data.user_email" id="AdminOrdersCreateUserEmail" type="email"
                               class="input-normal input-md"/>
                    </div>
                </li>
                <li class="col-span-full">
                    <h3 class="mb-2">Спосіб оплати</h3>
                    <ul class="w-full grid grid-cols-2 gap-2">
                        <li>
                            <input v-model.number="data.payment_method_id" type="radio"
                                   id="AdminOrderCreatePaymentMethodCard" name="payment_method_id" value="0"
                                   class="hidden peer" required/>
                            <label for="AdminOrderCreatePaymentMethodCard"
                                   class="inline-flex items-center justify-between w-full p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-primary peer-checked:text-primary hover:text-primary-light">
                                <div class="block">
                                    <div class="w-full text-lg font-semibold">На карту</div>
                                </div>
                            </label>
                        </li>
                        <li>
                            <input v-model.number="data.payment_method_id" type="radio"
                                   id="AdminOrderCreatePaymentMethodOnReceived" name="payment_method_id" value="1"
                                   class="hidden peer">
                            <label for="AdminOrderCreatePaymentMethodOnReceived"
                                   class="inline-flex items-center justify-between w-full p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-primary peer-checked:text-primary hover:text-primary-light">
                                <div class="block">
                                    <div class="w-full text-lg font-semibold">При отриманні</div>
                                </div>
                            </label>
                        </li>
                    </ul>

                </li>
                <li>
                    <div class="flex flex-col gap-2">
                        <label for="AdminOrdersCreateDeliveryArea">Область</label>
                        <loading-spinner :loading="npLoading.areas"/>
                        <select v-show="!npLoading.areas"
                                v-model="data.area"
                                @change="setArea($event.target)"
                                id="AdminOrdersCreateDeliveryArea"
                                class="input-normal input-md">
                            <option value="" selected disabled>- вибрати -</option>
                            <option v-for="area in areas"
                                    :key="area['Ref']"
                                    :value="area['Description']"
                                    :data-ref="area['Ref']"
                            >{{ area['Description'] }}
                            </option>
                        </select>
                    </div>
                </li>

                <li>
                    <div v-show="selected.areas" class="flex flex-col gap-2">
                        <label for="AdminOrdersCreateDeliveryCity">Місто</label>
                        <loading-spinner :loading="npLoading.cities"/>
                        <select v-show="!npLoading.cities"
                                v-model="data.city"
                                @change="setCity($event.target)"
                                id="AdminOrdersCreateDeliveryCity"
                                name="user_id"
                                class="input-normal input-md">
                            <option value="" selected disabled>- вибрати -</option>
                            <option v-for="city in cities"
                                    :key="city['Ref']"
                                    :value="city['Description']"
                                    :data-ref="city['Ref']"
                            >{{ city['Description'] }}
                            </option>
                        </select>
                    </div>
                </li>

                <li class="col-span-full">
                    <div v-show="selected.cities" class="flex flex-col gap-2">
                        <label for="AdminOrdersCreateDeliveryWarehouse">Відділення</label>
                        <loading-spinner :loading="npLoading.warehouses"/>
                        <select v-show="!npLoading.warehouses"
                                v-model="data.warehouse"
                                @change="setWarehouse($event.target)"
                                id="AdminOrdersCreateDeliveryWarehouse"
                                class="input-normal input-md">
                            <option value="" selected disabled>- вибрати -</option>
                            <option v-for="wh in warehouses"
                                    :key="wh['Ref']"
                                    :value="wh['Description']"
                                    :data-ref="wh['Ref']"
                            >{{ wh['Description'] }}
                            </option>
                        </select>
                    </div>
                </li>

                <li class="col-span-full">
                    <label for="AdminOrdersCreateDesc">Опис</label>
                    <textarea v-model="data.desc" id="AdminOrdersCreateDesc"
                              class="mt-2 input-normal input-md"></textarea>
                </li>

                <li>
                    <div class="flex flex-col gap-2">
                        <label for="AdminOrdersCreateSource">Джерело замовлення</label>
                        <loading-spinner :loading="sourcesLoading"/>
                        <select v-show="!sourcesLoading"
                                v-model="data.source_id"
                                id="AdminOrdersCreateSource"
                                class="input-normal input-md">
                            <option value="" selected disabled>- вибрати -</option>
                            <option v-for="(source, key) in sources"
                                    :key="key"
                                    :value="key"
                            >{{ source.name }}
                            </option>
                        </select>
                    </div>
                </li>
            </ul>
        </div>
        <div class="flex justify-between items-center gap-2">
            <span class="font-semibold text-2xl">Сума: {{ total_price.toFixed(2) }} ₴</span>

            <button v-show="!edit && !orderLoading.order" @click.stop="createOrder(data)" type="submit"
                    class="btn-lg btn-primary">Створити
            </button>

            <button v-show="edit && !orderLoading.order" @click.stop="updateOrder(data)" type="submit"
                    class="btn-lg btn-primary">Зберегти
            </button>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {useUserStore, useItemStore, useNovaPoshtaStore, useOrderStore} from "../../stores";
import LoadingSpinner from "../LoadingSpinner.vue";

export default {
    name: 'OrderCreate',
    components: {LoadingSpinner},

    data() {
        return {
            data: {
                user_id: '',
                user_name: '',
                user_email: '',
                user_phone: '',
                payment_method_id: '',
                area: '',
                np_area_ref: '',
                city: '',
                np_city_ref: '',
                warehouse: '',
                np_warehouse_ref: '',
                desc: '',
                source_id: 0,
                items: [],
            },
            selectedItems: [],
            availableItems: [],
            amounts: [],
            total_price: 0,
        }
    },

    computed: {
        ...mapState(useUserStore, {
            users: 'getUsers',
        }),
        ...mapState(useItemStore, {
            items: 'getItems',
        }),
        ...mapState(useNovaPoshtaStore, {
            areas: 'getAreas',
            cities: 'getCities',
            warehouses: 'getWarehouses',
            selected: 'getSelected',
            npLoading: 'getLoading',
        }),
        ...mapState(useOrderStore, {
            order: 'getOrder',
            sources: 'getSources',
            orderLoading: 'orderLoading',
            sourcesLoading: 'sourcesLoading',
        }),

        edit() {
            return parseInt(window.location.href.split('/').pop()) > 0
        },
    },

    methods: {
        ...mapActions(useUserStore, [
            'fetchUsers',
        ]),
        ...mapActions(useItemStore, [
            'fetchItems',
        ]),
        ...mapActions(useNovaPoshtaStore, [
            'fetchAreas',
            'fetchCities',
            'fetchWarehouses',
        ]),
        ...mapActions(useOrderStore, [
            'fetchOrder',
            'fetchSources',
            'createOrder',
            'updateOrder',
        ]),

        setArea(ref) {
            this.data.np_area_ref = ref.selectedOptions[0].dataset.ref
            this.fetchCities(ref.selectedOptions[0].dataset.ref)
        },

        setCity(ref) {
            this.data.np_city_ref = ref.selectedOptions[0].dataset.ref
            this.fetchWarehouses(ref.selectedOptions[0].dataset.ref)
        },

        setWarehouse(ref) {
            this.data.np_warehouse_ref = ref.selectedOptions[0].dataset.ref
        },

        addItem() {
            if (this.data.items.length === 0 || this.data.items[this.data.items.length - 1].item_id != null) {
                this.data.items.push({
                    item_id: null,
                    amount: 1,
                    item_price: 0,
                    total_price: 0,
                    purchase_price: 0,
                })

                this.amounts.push(0)
            }
        },

        selectItem(i, item_id) {
            let item = this.items.find(item => item.id === item_id)

            this.amounts[i] = item.max_amount
            this.data.items[i].item_price = item.price ?? 0

            this.recountItem(i)
        },

        decrementItem(i) {
            this.data.items[i].amount -= 1
            this.recountItem(i)
        },

        incrementItem(i) {
            this.data.items[i].amount += 1
            this.recountItem(i)
        },

        recountItem(i) {
            this.total_price = 0
            this.data.items[i].total_price = (this.data.items[i].amount * this.data.items[i].item_price).toFixed(2)
            this.data.items.forEach(item => this.total_price += parseFloat(item.total_price))
        },

        deleteItem(i) {
            this.amounts = this.amounts.filter((item, index) => index !== i)
            this.data.items = this.data.items.filter((item, index) => index !== i)

            this.recountItem(i)
        },

        isMinAmountReached(i) {
            return this.data.items[i].amount <= 1
        },

        isMaxAmountReached(i) {
            return this.amounts[i] <= this.data.items[i].amount
        },

        handleItemAmount(i) {
            if (this.isMinAmountReached(i)) this.data.items[i].amount = 1
            if (this.isMaxAmountReached(i)) this.data.items[i].amount = this.amounts[i]
        },

        handleUserChange() {
            if (this.data.user_id) {
                this.data.user_name = '';
                this.data.user_email = '';
                this.data.user_phone = '';
            }
        },

    },

    watch: {
        order(newOrder) {
            if (this.edit) {
                this.data = newOrder

                if (!newOrder.user_id) {
                    this.data.user_name = newOrder.user.name;
                    this.data.user_email = newOrder.user.email;
                    this.data.user_phone = newOrder.user.phone;
                }

                this.total_price = newOrder.total_price
            }
        }
    },

    async created() {
        this.fetchUsers()
        this.fetchItems()
        this.fetchAreas()
        this.fetchSources()
        if (this.edit) await this.fetchOrder(parseInt(window.location.href.split('/').pop()))
    }

}
</script>
