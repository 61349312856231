import {defineStore} from 'pinia'

import CatalogService from '../services/CatalogService'

export const useTrendingStore = defineStore('trending', {
    state: () => ({
        trending: {},
        loading: {
            trending: false
        },
    }),

    getters: {
        getTrending: (state) => state.trending,
        trendingLoading: (state) => state.loading,
    },

    actions: {
        async fetchTrending(id){
            this.loading.trending = true
            this.trending = {}

            try {
                const res = await CatalogService.fetchTrending(id)
                this.trending = res.data.data

            } catch (e) {

            } finally {
                this.loading.trending = false
            }
        },

        async createTrending(trending) {
            this.loading.trending = true
            try {
                const res = await CatalogService.createTrending(trending)

                if(res.status === 200){
                    $('#AdminTrendingCreateModal').fadeOut(400)
                }
            } catch (e) {

            } finally {
                this.loading.trending = false
            }
        },

        async updateTrending(trending) {
            this.loading.trending = true
            try {
                const res = await CatalogService.updateTrending(trending)

                if(res.status === 200){
                    $('#AdminTrendingEditModal').fadeOut(400)
                }

            } catch (e) {

            } finally {
                this.loading.trending = false
            }
        },

    },
})
