import {defineStore} from 'pinia'

import OfferService from '../services/OfferService'

export const useOfferStore = defineStore('offer', {
    state: () => ({
        offer: {},
        loading: {
            offer: false
        },
    }),

    getters: {
        getOffer: (state) => state.offer,
        offerLoading: (state) => state.loading,
    },

    actions: {
        async fetchOffer(id){
            this.loading.offer = true
            this.offer = {}

            try {
                const res = await OfferService.fetchOffer(id)
                this.offer = res.data.data

            } catch (e) {

            } finally {
                this.loading.offer = false
            }
        },

        async createOffer(offer) {
            this.loading.offer = true
            try {
                const res = await OfferService.createOffer(offer)

                if(res.status === 200){
                    $('#AdminOfferCreateModal').fadeOut(400)
                }
            } catch (e) {

            } finally {
                this.loading.offer = false
            }
        },

        async updateOffer(offer) {
            this.loading.offer = true
            try {
                const res = await OfferService.updateOffer(offer)

                if(res.status === 200){
                    $('#AdminOfferEditModal').fadeOut(400)
                }

            } catch (e) {

            } finally {
                this.loading.offer = false
            }
        },

    },
})
