import ApiRequestProvider from '../providers/ApiRequestProvider'

export default {
    async fetchUsers(){
        return await ApiRequestProvider._get('users')
    },

    async fetchCurrentUser(){
        return await ApiRequestProvider._get('user')
    }
}
