require( './bootstrap' );

import { createApp } from 'vue'
import Checkout from "./components/Checkout/Checkout.vue";
import { vMaska } from "maska"

const checkout = createApp( Checkout )

checkout.directive( 'maska', vMaska )
if ( document.getElementById( 'PublicCheckout' ) ) window.checkout = checkout.mount( '#PublicCheckout' )
