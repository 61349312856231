const $ = require('jquery');
import 'slick-carousel'

const options = {}

$('.single-item').slick({
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    pauseOnHover: false
});


if (window.innerWidth <= 767) {
    options.popularItemsOptions = {}
}

if (window.innerWidth <= 1279) {
    options.popularItemsOptions = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1
    }
}

if (window.innerWidth <= 1599) {
    options.popularItemsOptions = {
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 1
    }
}

$('.popular-items-slider').slick(options.popularItemsOptions);

$('.catalog-slider').slick({
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    pauseOnHover: false,
});
