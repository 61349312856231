import {defineStore} from 'pinia'

import UserService from '../services/UserService'
import NovaPoshtaService from "../services/NovaPoshtaService";

export const useNovaPoshtaStore = defineStore('novaposhta', {
    state: () => ({
        areas: [],
        cities: [],
        warehouses: [],
        selected: {
          area: false,
          city: false,
        },

        loading: {
            areas: false,
            cities: false,
            warehouses: false,
        }
    }),

    getters: {
        getAreas: (state) => state.areas,
        getCities: (state) => state.cities,
        getWarehouses: (state) => state.warehouses,
        getSelected: (state) => state.selected,
        getLoading: (state) => state.loading,
    },

    actions: {
        async fetchAreas() {
            this.loading.areas = true
            this.selected.areas = false
            this.selected.cities = false
            try {
                const res = await NovaPoshtaService.fetchAreas()
                this.areas = res.data.data
            } catch (e) {

            } finally {
                this.loading.areas = false
            }
        },
        async fetchCities(area) {
            this.loading.cities = true
            this.selected.areas = true
            this.selected.cities = false
            try {
                const res = await NovaPoshtaService.fetchCities(area)
                this.cities = res.data.data
            } catch (e) {

            } finally {
                this.loading.cities = false
            }
        },
        async fetchWarehouses(city) {
            this.loading.warehouses = true
            this.selected.cities = true
            try {
                const res = await NovaPoshtaService.fetchWarehouses(city)
                this.warehouses = res.data.data
            } catch (e) {

            } finally {
                this.loading.warehouses = false
            }
        },
    },
})
