const $ = require('jquery');

$('form').on('input', '.input-transliterate', function (e) {
    console.log(e.target)
    if ($(e.target).attr('data-transliterate-to').length > 0) {
        $($(e.target).attr('data-transliterate-to')).val(transliterateCyr($(e.target).val()));
    }
});

function transliterateCyr(str) {
    str = str.toLowerCase();

    const converter = [
        ['а', 'a'], ['б', 'b'], ['в', 'v'], ['г', 'g'], ['д', 'd'], ['е', 'e'], ['ё', 'yo'], ['ж', 'zh'], ['з', 'z'], ['и', 'y'], ['ї', 'yi'], ['й', 'y'], ['к', 'k'], ['л', 'l'], ['м', 'm'], ['н', 'n'], ['о', 'o'], ['п', 'p'], ['р', 'r'], ['с', 's'], ['т', 't'], ['у', 'u'], ['ф', 'f'], ['х', 'h'], ['ц', 'c'], ['ч', 'ch'], ['ш', 'sh'], ['щ', 'sh'], ['ъ', ''], ['ы', 'y'], ['ь', ''], ['э', 'e'], ['є', 'e'], ['ю', 'yu'], ['я', 'ya'],
        ['А', 'A'], ['Б', 'B'], ['В', 'V'], ['Г', 'G'], ['Д', 'D'], ['Е', 'E'], ['Ё', 'Yo'], ['Ж', 'Zh'], ['З', 'Z'], ['И', 'Y'], ['Ї', 'Yi'], ['Й', 'Y'], ['К', 'K'], ['Л', 'L'], ['М', 'M'], ['Н', 'N'], ['О', 'O'], ['П', 'P'], ['Р', 'R'], ['С', 'S'], ['Т', 'T'], ['У', 'U'], ['Ф', 'F'], ['Х', 'H'], ['Ц', 'C'], ['Ч', 'Ch'], ['Ш', 'Sh'], ['Щ', 'Sh'], ['Ъ', ''], ['Ы', 'Y'], ['Ь', ''], ['Э', 'E'], ['Є', 'E'], ['Ю', 'Yu'], ['Я', 'Ya'],

        ['a', 'a'], ['b', 'b'], ['c', 'c'], ['d', 'd'], ['e', 'e'], ['f', 'f'], ['g', 'g'], ['h', 'h'], ['i', 'i'], ['j', 'j'], ['k', 'k'], ['l', 'l'], ['m', 'm'], ['n', 'n'], ['o', 'o'], ['p', 'p'], ['q', 'q'], ['r', 'r'], ['s', 's'], ['t', 't'], ['u', 'u'], ['v', 'v'], ['w', 'w'], ['x', 'x'], ['y', 'y'], ['z', 'z'],
        ['A', 'A'], ['B', 'B'], ['C', 'C'], ['D', 'D'], ['E', 'E'], ['F', 'F'], ['G', 'G'], ['H', 'H'], ['I', 'I'], ['J', 'J'], ['K', 'K'], ['L', 'L'], ['M', 'M'], ['N', 'N'], ['O', 'O'], ['P', 'P'], ['Q', 'Q'], ['R', 'R'], ['S', 'S'], ['T', 'T'], ['U', 'U'], ['V', 'V'], ['W', 'W'], ['X', 'X'], ['Y', 'Y'], ['Z', 'Z'],

        ['0', '0'], ['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7'], ['8', '8'], ['9', '9'],

        [' ', '-'], ['-', '-'], ['_', '-'], ['і', 'i'], ['\'', 'i'], ['`', 'i']
    ];

    let result = '', ch = '';

    for (let i = 0; i < str.length; i++) {
        ch = str.charAt(i);
        let new_char = '';
        for (let j = 0; j < converter.length; j++) {
            if (ch === converter[j][0]) {
                new_char = converter[j][1];
            }
        }

        result += new_char;
    }

    return result.replace(/_{2,}/gim, '_');
}
