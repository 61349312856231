import {createApp} from 'vue'
import {createPinia} from 'pinia'
import {vMaska} from "maska"
import TrendingCreate from './components/Trending/TrendingCreate.vue'

const trendingCreate = createApp(TrendingCreate)

if (document.getElementById('AdminTrendingCreateApp')) {
    const pinia = createPinia()
    trendingCreate.use(pinia)
    trendingCreate.directive('maska', vMaska)
    window.trendingCreate = trendingCreate.mount('#AdminTrendingCreateApp')
}
