import {defineStore} from 'pinia'

import UserService from '../services/UserService'

export const useUserStore = defineStore('user', {
    state: () => ({
        users: [],
        currentUser: undefined
    }),

    getters: {
        getUsers: (state) => state.users,
        getCurrentUser: (state) => state.currentUser,
    },

    actions: {
        async fetchUsers() {
            try {
                const res = await UserService.fetchUsers()
                this.users = res.data.data
            } catch (e) {

            }
        },

        async fetchCurrentUser() {
            try {
                const res = await UserService.fetchCurrentUser()
                this.currentUser = res.data
            } catch (e) {

            }
        },
    },
})
