<template>
    <div class="w-full -mt-16">
        <ul class="flex justify-end space-x-6">
            <li @click="setDashboardInterval('day')" class="p-2 rounded-xl cursor-pointer"
                :class="{'bg-white shadow-md': interval === 'day'}">День
            </li>
            <li @click="setDashboardInterval('week')" class="p-2 rounded-xl cursor-pointer"
                :class="{'bg-white shadow-md': interval === 'week'}">Тиждень
            </li>
            <li @click="setDashboardInterval('month')" class="p-2 rounded-xl cursor-pointer"
                :class="{'bg-white shadow-md': interval === 'month'}">Місяць
            </li>
            <li @click="setDashboardInterval('year')" class="p-2 rounded-xl cursor-pointer"
                :class="{'bg-white shadow-md': interval === 'year'}">Рік
            </li>
        </ul>
    </div>
    <div class="mt-12 grid grid-cols-12 gap-8">
        <div class="col-span-4 p-4 bg-white rounded-2xl shadow-md flex flex-row items-center space-x-4">
            <div class="icon px-6 py-4">
                <i class="mdi mdi-handshake-outline text-4xl"></i>
            </div>
            <div class="leading-normal">
                <h4 class="font-semibold text-xl">Замовлення</h4>
                <span>{{ currOrdersCount }}</span>
                <span class="ml-4" :class="showGrowthPercentageColor(ordersGrowthPercentage)">
					<span class="text-gray-400">{{ prevOrdersCount }}</span>
					{{ showGrowthPercentage(ordersGrowthPercentage) }}
				</span>
            </div>
        </div>

        <div class="col-span-4 p-4 bg-white rounded-2xl shadow-md flex flex-row items-center space-x-4">
            <div class="icon px-6 py-4">
                <i class="mdi mdi-account-group-outline text-4xl"></i>
            </div>
            <div class="leading-normal">
                <h4 class="font-semibold text-xl">Клієнти</h4>
                <span>{{ currCustomersCount }}</span>
                <span class="ml-4" :class="showGrowthPercentageColor(customersGrowthPercentage)">
					<span class="text-gray-400">{{ prevCustomersCount }}</span>
					{{ showGrowthPercentage(customersGrowthPercentage) }}
				</span>
            </div>
        </div>

        <div class="col-span-4 p-4 bg-white rounded-2xl shadow-md flex flex-row items-center space-x-4">
            <div class="icon px-6 py-4">
                <i class="mdi mdi-cash-multiple text-4xl"></i>
            </div>
            <div class="leading-normal">
                <h4 class="font-semibold text-xl">Дохід</h4>
                <span>{{ currIncome }}</span>
                <span class="ml-4" :class="showGrowthPercentageColor(salesGrowthPercentage)">
					<span class="text-gray-400">{{ prevIncome }}</span>
					{{ showGrowthPercentage(salesGrowthPercentage) }}
				</span>
            </div>
        </div>

<!--        <div class="col-span-3 p-4 bg-white rounded-2xl shadow-md flex flex-row items-center space-x-4">-->
<!--            <div class="icon px-6 py-4">-->
<!--                <i class="mdi mdi-account-group-outline text-4xl"></i>-->
<!--            </div>-->
<!--            <div class="leading-normal">-->
<!--                <h4 class="font-semibold text-xl">-</h4>-->
<!--                <span>{{ '-' }}</span>-->
<!--            </div>-->
<!--        </div>-->

        <div class="col-span-12 p-4 bg-white rounded-2xl shadow-md">
            <h4 class="font-semibold">Доходи</h4>
            <div id="OrdersIncomeChart" ref="OrdersIncomeChart" class="h-96 mt-4"></div>
        </div>

        <div class="col-span-4 p-4 bg-white rounded-2xl shadow-md">
            <h4 class="font-semibold">Джерела</h4>
            <div id="OrdersSourcesChart" ref="OrdersSourcesChart" class="h-96 mt-4"></div>
        </div>

    </div>
</template>

<script>
import DashboardService from "../../services/DashboardService";
import OrderService from "../../services/OrderService";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
    data() {
        return {
            interval: 'week',

            currOrdersCount: 0,
            prevOrdersCount: 0,
            ordersGrowthPercentage: 0,

            currCustomersCount: 0,
            prevCustomersCount: 0,
            customersGrowthPercentage: 0,

            currIncome: 0,
            prevIncome: 0,
            salesGrowthPercentage: 0,

            ordersIncomeChart: null,
            ordersSourcesChart: null,

        }
    },

    methods: {
        async fetchDashboard() {
            const dashboardResponse = await DashboardService.fetchDashboard({interval: this.interval})
            const prevData = dashboardResponse.data.data.prev
            const currData = dashboardResponse.data.data.curr

            // console.log( 'prevData', prevData )
            // console.log( 'currData', currData )

            this.currCustomersCount = currData.customers
            this.prevCustomersCount = prevData.customers
            this.customersGrowthPercentage = this.getGrowthPercentage(prevData.customers, currData.customers)

            this.currOrdersCount = currData.orders.length
            this.prevOrdersCount = prevData.orders.length
            this.ordersGrowthPercentage = this.getGrowthPercentage(prevData.orders.length, currData.orders.length)

            let currIncome = this.getOrdersTotalIncome(currData.orders)
            let prevIncome = this.getOrdersTotalIncome(prevData.orders)
            this.salesGrowthPercentage = this.getGrowthPercentage(prevIncome, currIncome)
            this.currIncome = this.moneyFormat(currIncome)
            this.prevIncome = this.moneyFormat(prevIncome)

            await this.drawOrdersIncomeChart()
            await this.drawOrdersSourcesChart()

        },

        async setDashboardInterval(interval) {
            this.interval = interval
            await this.ordersIncomeChart.dispose()
            await this.ordersSourcesChart.dispose()

            await this.fetchDashboard()
        },

        getOrdersTotalIncome(orders = []) {
            let income = 0

            orders.forEach(order => {
                income += order.total_price
            })

            return income
        },

        async drawOrdersIncomeChart() {
            let chart = am4core.create(this.$refs.OrdersIncomeChart, am4charts.XYChart)

            // chart.paddingRight = 20;

            // Add data
            const ordersTotalIncomeByIntervalResponse = await OrderService.fetchOrdersIncome(this.interval)
            chart.data = Object.values(ordersTotalIncomeByIntervalResponse.data.data)

            // Create axes
            let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
            categoryAxis.dataFields.category = "interval"
            categoryAxis.renderer.grid.template.location = 0
            categoryAxis.renderer.minGridDistance = chart.data.length
            if (this.interval === 'day' || this.interval === 'month') {
                categoryAxis.renderer.labels.template.rotation = -90
                categoryAxis.renderer.labels.template.horizontalCenter = "left"
                categoryAxis.renderer.labels.template.location = 0
            }
            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())

            // Create series
            let series = chart.series.push(new am4charts.ColumnSeries())
            series.dataFields.valueY = "income"
            series.dataFields.categoryX = "interval"
            series.name = "Income"
            series.fill = am4core.color("#4CAF50")
            series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]"
            series.columns.template.column.cornerRadiusTopLeft = 8
            series.columns.template.column.cornerRadiusTopRight = 8

            this.ordersIncomeChart = chart
        },

        async drawOrdersSourcesChart() {
            let chart = am4core.create(this.$refs.OrdersSourcesChart, am4charts.PieChart)
            const colors = [
                '#FF5733',
                '#33FF57',
                '#3357FF',
                '#FF33A1',
                '#A133FF',
                '#33FFF5',
                '#FFA133',
                '#57FF33',
                '#FF3357',
                '#33A1FF',
                '#A1FF33',
                '#5733FF',
                '#FF33F5',
                '#33FFA1',
                '#A1FF57',
            ]

            const ordersSourcesByIntervalResponse = await OrderService.fetchOrdersSources(this.interval)
            Object.values(ordersSourcesByIntervalResponse.data.data).forEach((val, i) => {
                chart.data.push({count: val.count, source: val.source, color: am4core.color(colors[i])})
            })

            // Create chart instance

            // Add and configure Series
            let pieSeries = chart.series.push(new am4charts.PieSeries())
            pieSeries.dataFields.value = "count"
            pieSeries.dataFields.category = "source"

            // Let's cut a hole in our Pie chart the size of 30% the radius
            chart.innerRadius = am4core.percent(60)

            // Put a thick white border around each Slice
            pieSeries.slices.template.stroke = am4core.color("#fff")
            pieSeries.slices.template.strokeWidth = 4
            pieSeries.slices.template.cornerRadius = 12
            pieSeries.slices.template.propertyFields.fill = "color"

            // change the cursor on hover to make it apparent the object can be interacted with
            pieSeries.slices.template.cursorOverStyle = [{"property": "cursor", "value": "pointer"}]

            // Add a legend
            // chart.legend = new am4charts.Legend()

            this.ordersSourcesChart = chart
        },

        getGrowthPercentage(prev, curr) {
            return (((curr - prev) / prev) * 100).toFixed(2)
        },

        showGrowthPercentage(percentage) {
            let symbol = (percentage > 0 ? '+' : '')
            let value = percentage
            // if (isNaN(percentage))
            if (!isFinite(percentage)) value = 100

            return `${symbol}${value}%`
        },

        showGrowthPercentageColor(percentage) {
            let color

            if (percentage > 0) color = 'text-green-400'
            if (percentage < 0) color = 'text-red-400'
            if (isNaN(percentage)) color = 'text-amber-400'
            if (!isFinite(percentage)) color = 'text-green-400'

            return color
        },


        moneyFormat(money) {
            const moneyFormat = new Intl.NumberFormat('uk-UA', {
                style: 'currency',
                currency: 'UAH',

                // These options are needed to round to whole numbers if that's what you want.
                //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
            })

            return moneyFormat.format(money)
        },

    },

    mounted() {
        this.fetchDashboard()
    },

}
</script>
