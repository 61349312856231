const $ = require( 'jquery' )

$('[data-copy]').on('click', function(e) {
    e.preventDefault()
    const temp = $('<input class="w-0 h-0">')
    $('body').append(temp)
    temp.val($(this).data('value')).select()
    document.execCommand('copy')
    temp.remove()
});
