import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {URL} from './config'
document.addEventListener('DOMContentLoaded', (event) => {
    const editors = document.querySelectorAll('.ckeditor');
    editors.forEach(editor => {
            const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            ClassicEditor
                .create(editor, {

                })
                .then(editor => {
                    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
                        return new MyUploadAdapter(loader, csrfToken);
                    };
                })
                .catch(error => {
                    console.error(error);
                });
    });
});

class MyUploadAdapter {
    constructor(loader, csrfToken) {
        this.loader = loader;
        this.csrfToken = csrfToken;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then( file => new Promise( ( resolve, reject ) => {
                this._initRequest();
                this._initListeners( resolve, reject, file );
                this._sendRequest( file );
            } ) );
    }

    abort() {
        if ( this.xhr ) {
            this.xhr.abort();
        }
    }

    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();

        xhr.open( 'POST', URL + '/admin/upload-image', true );
        xhr.responseType = 'json';
    }

    _initListeners( resolve, reject, file ) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file: ${ file.name }.`;

        xhr.addEventListener( 'error', () => reject( genericErrorText ) );
        xhr.addEventListener( 'abort', () => reject() );
        xhr.addEventListener( 'load', () => {
            const response = xhr.response;

            if ( !response || response.error ) {
                return reject( response && response.error ? response.error.message : genericErrorText );
            }

            resolve( {
                default: response.url
            } );
        } );

        if ( xhr.upload ) {
            xhr.upload.addEventListener( 'progress', evt => {
                if ( evt.lengthComputable ) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            } );
        }
    }

    _sendRequest( file ) {
        const data = new FormData();

        data.append( 'upload', file );

        data.append('_token', this.csrfToken);

        this.xhr.send( data );
    }
}

function MyCustomUploadAdapterPlugin( editor ) {
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        return new MyUploadAdapter( loader );
    };
}



