import ApiRequestProvider from '../providers/ApiRequestProvider'

export default {
    async fetchAreas() {
        return await ApiRequestProvider._get('np/areas')
    },
    async fetchCities(area) {
        return await ApiRequestProvider._post('np/cities', {Area: area})
    },
    async fetchWarehouses(city) {
        return await ApiRequestProvider._post('np/warehouses', {CityRef: city})
    }
}
