<template>
    <div v-if="price > 0" class="flex flex-wrap justify-between space-x-0 lg:flex-nowrap lg:space-x-6 xl:space-x-12">

        <div class="checkout-summary w-full lg:w-1/2 xl:w-2/3">

            <form action="/checkout" method="POST">

                <input type="hidden" name="_token" :value="csrf"/>

                <div class="flex flex-col gap-8">

                    <hr class="block lg:hidden border-dashed border-gray-400">

                    <h3 class="text-gray-700 text-xl font-semibold">Контактні дані</h3>

                    <ul class="grid grid-cols-1 sm:grid-cols-2 gap-6">
                        <li v-if="currentUser" class="col-span-full">
                            <div class="p-4 bg-white border border-gray-300 rounded-xl leading-normal">
                                <span class="text-2xl font-semibold">{{ currentUser.name }}</span>
                                <p>{{ currentUser.email }}</p>
                            </div>
                        </li>

                        <li v-else class="col-span-full">
                            <ul class="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                <li class="col-span-full">
                                    <label for="PublicCheckoutFormName" class="form-label">Ім'я</label>
                                    <input id="PublicCheckoutFormName" type="text" name="user_name"
                                           class="mt-2 input-md input-normal"
                                           required>
                                </li>

                                <li>
                                    <label for="PublicCheckoutFormPhone" class="form-label">Номер телефону</label>
                                    <input v-maska data-maska="+38 (###) ###-##-##"
                                           id="PublicCheckoutFormPhone" type="tel" name="user_phone"
                                           class="mt-2 input-md input-normal"
                                           required>
                                </li>

                                <li>
                                    <label for="PublicCheckoutFormEmail" class="form-label">Email</label>
                                    <input id="PublicCheckoutFormEmail" type="email" name="user_email"
                                           class="mt-2 input-md input-normal"
                                           required
                                           autocomplete="email">
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <hr class="border-dashed border-gray-400">

                    <h3 class="text-gray-700 text-xl font-semibold">Спосіб оплати</h3>

                    <ul class="w-full grid grid-cols-1 sm:grid-cols-2 gap-6">
                        <li>
                            <input v-model.number="data.payment_method_id" type="radio"
                                   id="PublicCheckoutPaymentMethodCard" name="payment_method_id" value="0"
                                   class="hidden peer" required/>
                            <label for="PublicCheckoutPaymentMethodCard"
                                   class="inline-flex items-center justify-between w-full p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-primary peer-checked:text-primary hover:text-primary-light">

                                <div class="w-full text-lg font-semibold">На карту</div>
                            </label>
                        </li>
                        <li>
                            <input v-model.number="data.payment_method_id" type="radio"
                                   id="PublicCheckoutPaymentMethodOnReceived" name="payment_method_id" value="1"
                                   class="hidden peer">
                            <label for="PublicCheckoutPaymentMethodOnReceived"
                                   class="inline-flex items-center justify-between w-full p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-primary peer-checked:text-primary hover:text-primary-light">
                                <div class="w-full text-lg font-semibold">При отриманні</div>
                            </label>
                        </li>
                    </ul>

                    <hr class="border-dashed border-gray-400">

                    <h3 class="text-gray-700 text-xl font-semibold">Доставка</h3>

                    <ul class="w-full grid grid-cols-1 sm:grid-cols-2 gap-6">
                        <li>
                            <div class="flex flex-col gap-2">
                                <label for="PublicCheckoutDeliveryArea">Область</label>
                                <loading-spinner :loading="npLoading.areas"/>

                                <input v-model="data.np_area_ref" name="np_area_ref" type="hidden" required>
                                <select v-show="!npLoading.areas"
                                        v-model="data.area"
                                        @change="setArea($event.target)"
                                        id="PublicCheckoutDeliveryArea"
                                        name="area"
                                        class="input-normal input-md">
                                    <option value="" selected disabled>- вибрати -</option>
                                    <option v-for="area in areas"
                                            :key="area['Ref']"
                                            :value="area['Description']"
                                            :data-ref="area['Ref']"
                                    >{{ area['Description'] }}
                                    </option>
                                </select>
                            </div>
                        </li>

                        <li>
                            <div v-show="selected.areas" class="flex flex-col gap-2">
                                <label for="PublicCheckoutDeliveryCity">Місто</label>
                                <loading-spinner :loading="npLoading.cities"/>

                                <input v-model="data.np_city_ref" name="np_city_ref" type="hidden" required>
                                <select v-show="!npLoading.cities"
                                        v-model="data.city"
                                        @change="setCity($event.target)"
                                        id="PublicCheckoutDeliveryCity"
                                        name="city"
                                        class="input-normal input-md">
                                    <option value="" selected disabled>- вибрати -</option>
                                    <option v-for="city in cities"
                                            :key="city['Ref']"
                                            :value="city['Description']"
                                            :data-ref="city['Ref']"
                                    >{{ city['Description'] }}
                                    </option>
                                </select>
                            </div>
                        </li>

                        <li class="col-span-full">
                            <div v-show="selected.cities" class="flex flex-col gap-2">
                                <label for="PublicCheckoutDeliveryWarehouse">Відділення</label>
                                <loading-spinner :loading="npLoading.warehouses"/>

                                <input v-model="data.np_warehouse_ref" name="np_warehouse_ref" type="hidden" required>
                                <select v-show="!npLoading.warehouses"
                                        v-model="data.warehouse"
                                        @change="setWarehouse($event.target)"
                                        id="PublicCheckoutDeliveryWarehouse"
                                        name="warehouse"
                                        class="input-normal input-md"
                                >
                                    <option value="" selected disabled>- вибрати -</option>
                                    <option v-for="wh in warehouses"
                                            :key="wh['Ref']"
                                            :value="wh['Description']"
                                            :data-ref="wh['Ref']"
                                    >{{ wh['Description'] }}
                                    </option>
                                </select>
                            </div>
                        </li>
                    </ul>

                    <hr class="border-dashed border-gray-400">

                    <div class="flex flex-col">
                        <h3 class="text-gray-700 text-xl font-semibold">Інше</h3>

                        <label for="PublicCheckoutFormDesc" class="form-label">Коментар</label>
                        <textarea id="PublicCheckoutFormDesc" rows="4" name="desc"
                                  class="mt-2 input-md input-normal"
                        ></textarea>
                    </div>

                    <hr v-if="!currentUser" class="border-dashed border-gray-400">

                    <div v-if="!currentUser">

                        <h3 class="text-gray-700 text-xl font-semibold">Реєстрація</h3>

                        <div class="mt-6 flex items-center">
                            <input v-model="register" id="PublicCheckoutFormRegister" type="checkbox"
                                   name="register"
                                   value="1"
                                   class="w-4 h-4 text-green-500 bg-white border-gray-300 rounded-md focus:ring-2 focus:ring-gray-300">
                            <label for="PublicCheckoutFormRegister" class="ml-2 select-none">Зберегти дані</label>
                        </div>

                        <ul v-if="register" class="mt-6 w-full grid grid-cols-1 sm:grid-cols-2 gap-6">
                            <li>
                                <label for="PublicCheckoutFormPassword" class="form-label">
                                    Пароль
                                </label>
                                <input v-model="data.user_password" id="PublicCheckoutFormPassword" type="password"
                                       name="user_password" minlength="8"
                                       class="mt-2 input-md input-normal"
                                       required>
                            </li>

                            <li>
                                <label for="PublicCheckoutFormPasswordConfirm" class="form-label">
                                    Підтвердження паролю
                                </label>
                                <input v-model="data.user_password_confirm" id="PublicCheckoutFormPasswordConfirm"
                                       type="password"
                                       name="user_password_confirmation" minlength="8"
                                       class="mt-2 input-md input-normal"
                                       required>
                            </li>
                        </ul>
                    </div>

                    <hr class="border-dashed border-gray-400">

                    <button
                        :disabled="buttonDisabled"
                        type="submit"
                        class="w-full btn-lg btn-primary disabled:opacity-50 disabled:grayscale">
                        ОФОРМИТИ
                    </button>
                </div>
            </form>

        </div>

        <div class="checkout-items order-first w-full lg:order-last lg:w-1/2 xl:w-1/3">
            <h3 class="text-gray-700 text-xl font-semibold">Замовлення</h3>

            <ul class="w-full mt-6 flex flex-col gap-y-2">
                <li v-for="item in items">
                    <ul class="w-full grid grid-cols-2 items-center gap-2">
                        <li class="col-span-full">
                            <h5 class="font-semibold font-oswald">{{ item.name }}</h5>
                        </li>

                        <li>
                            <img :src="item.image" alt="" class="w-full h-auto rounded-lg">
                        </li>

                        <li>
                            <ul class="grid grid-cols-2 gap-2 whitespace-nowrap">
                                <li class="col-span-full text-right">{{ item.total_price_display }}</li>

                                <li>
                                    <i v-if="item.amount === 1"
                                       @click="decrement(item.id)"
                                       class="mdi mdi-minus p-2 text-neutral-300"></i>

                                    <i v-else
                                       @click="decrement(item.id)"
                                       class="mdi mdi-minus p-2 cursor-pointer"></i>

                                    <span class="p-2">{{ item.amount }}</span>

                                    <i v-if="item.amount < item.amount_available"
                                       @click="increment(item.id)"
                                       class="mdi mdi-plus p-2 cursor-pointer"></i>

                                    <i v-else
                                       class="mdi mdi-plus p-2 cursor-pointer text-neutral-300"></i>
                                </li>

                                <li class="text-right">
                                    <i @click="remove(item.id)"
                                       class="mdi mdi-trash-can-outline py-2 text-red-400 cursor-pointer"></i>
                                </li>
                            </ul>
                        </li>
                    </ul>

                </li>
            </ul>

            <hr class="my-12 border-dashed border-gray-400">

            <ul class="mb-4 text-right text-xl">
                <li>
                    <span class="font-bold">ВСЬОГО: </span> {{ priceDisplay }}
                </li>
            </ul>

        </div>

    </div>

    <div v-else class="py-4 text-center text-gray-400">Кошик пустий</div>

</template>

<script>
import {mapActions, mapState} from "pinia";
import {useCartStore, useUserStore, useNovaPoshtaStore} from "../../stores";
import LoadingSpinner from "../LoadingSpinner.vue";

export default {
    components: {LoadingSpinner},

    data() {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),

            data: {
                user_password: '',
                user_password_confirm: '',
                area: '',
                np_area_ref: '',
                city: '',
                np_city_ref: '',
                warehouse: '',
                np_warehouse_ref: '',

                items: [],
            },

            register: false,
        }
    },

    computed: {
        ...mapState(useUserStore, {
            currentUser: 'getCurrentUser',
        }),
        ...mapState(useCartStore, {
            items: 'getCartItems',
            price: 'getCartPrice',
            priceDisplay: 'getCartPriceDisplay',
        }),
        ...mapState(useNovaPoshtaStore, {
            areas: 'getAreas',
            cities: 'getCities',
            warehouses: 'getWarehouses',
            selected: 'getSelected',
            npLoading: 'getLoading',
        }),
        buttonDisabled() {
            return this.register
                && this.data.user_password.length === 0 && this.data.user_password_confirm.length === 0
                || this.data.user_password !== this.data.user_password_confirm
        }
    },

    methods: {
        ...mapActions(useUserStore, [
            'fetchCurrentUser',
        ]),
        ...mapActions(useCartStore, [
            'fetchCart',
            'add',
            'increment',
            'decrement',
            'remove',
            'clear',
        ]),
        ...mapActions(useNovaPoshtaStore, [
            'fetchAreas',
            'fetchCities',
            'fetchWarehouses',
        ]),

        setArea(ref) {
            this.data.np_area_ref = ref.selectedOptions[0].dataset.ref
            this.fetchCities(ref.selectedOptions[0].dataset.ref)
        },

        setCity(ref) {
            this.data.np_city_ref = ref.selectedOptions[0].dataset.ref
            this.fetchWarehouses(ref.selectedOptions[0].dataset.ref)
        },

        setWarehouse(ref) {
            this.data.np_warehouse_ref = ref.selectedOptions[0].dataset.ref
        },
    },

    watch: {
        currentUser(newCurrentUser) {
            this.data.user_id = newCurrentUser.id
        },
    },

    created() {
        this.fetchCurrentUser()
        this.fetchAreas()
    },
}
</script>
