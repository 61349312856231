import {defineStore} from 'pinia'

import OrderService from '../services/OrderService'

export const useOrderStore = defineStore('order', {
    state: () => ({
        order: {},
        sources: [],
        loading: {
            order: false,
            sources: false,
        },
    }),

    getters: {
        getOrder: (state) => state.order,
        getSources: (state) => state.sources,
        orderLoading: (state) => state.loading.order,
        sourcesLoading: (state) => state.loading.sources,
    },

    actions: {
        async fetchOrder(id) {
            this.loading.order = true
            this.order = {}

            try {
                const res = await OrderService.fetchOrder(id)
                this.order = res.data.data

            } catch (e) {

            } finally {
                this.loading.order = false
            }
        },

        async fetchSources() {
            this.loading.sources = true
            this.sources = []

            try {
                const res = await OrderService.fetchSources()
                this.sources = res.data.data

            } catch (e) {

            } finally {
                this.loading.sources = false
            }
        },

        async createOrder(order) {
            this.loading.order = true
            try {
                const res = await OrderService.createOrder(order)

                if (res.status === 200) {
                    $('#AdminOrderCreateModal').fadeOut(400)
                }

            } catch (e) {

            } finally {
                this.loading.order = false
            }
        },

        async updateOrder(order) {
            this.loading.order = true
            try {
                const res = await OrderService.updateOrder(order)

                if (res.status === 200) {
                    $('#AdminOrderEditModal').fadeOut(400)
                }

            } catch (e) {

            } finally {
                this.loading.order = false
            }
        },
    },
})
