require('./bootstrap');

import "@mdi/font/css/materialdesignicons.css";

import "./accordion"
import "./alert"
import "./clipboard"
import "./dropdown"
import "./images"
import "./inputmask"
import "./keyboard"
import "./modal"
import "./pagination"
import "./transliterate"

import "./cart"
import "./catalog"
import "./checkout"
import "./dashboard"
import "./pages"
import "./offer"
import "./order"
import "./slider"
import "./trending"
