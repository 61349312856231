import {createApp} from 'vue'
import {createPinia} from 'pinia'
import Cart from "./components/Cart/Cart.vue";

const cart = createApp(Cart)

if (document.getElementById('PublicNavCart')) {
    const pinia = createPinia()
    cart.use(pinia)
    window.cart = cart.mount('#PublicNavCart')
}
