const $ = require('jquery');

$(document).ready(function() {
    $('#PublicCatalogItemsIndexFormSort').change(function() {
        $(this.dataset.target).val(this.selectedOptions[0].value)
        $(this.dataset.target).closest('form').submit()
    });

    $('#PublicCatalogItemAddToCart').click(function(){
        window.cart.add($(this).data('id'))
        window.cart.show()
    })
});
