const $ = require( 'jquery' );

window.pagination = {
    firstPage(target) {
        $(target).val(1).closest('form').submit()
    },

    setPage(target, page) {
        $(target).val(page).closest('form').submit()
    },
}
