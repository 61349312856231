<template>
    <div id="AdminOffersCreateForm">
        <div class="grid grid-cols-2 gap-6 py-4">
            <div class="flex flex-col gap-2">
                <div class="flex flex-row justify-between items-center">
                    <h3 class="text-lg font-semibold">Товари</h3>
                    <label>Вибрати все
                        <input v-model.number="selectAll" type="checkbox" name="select_all" :value="1"/>
                    </label>
                </div>
                <ul class="flex flex-row items-center gap-2">
                    <li v-for="(category, i) in categories" :key="i">
                        <input v-model.number="filters.category" type="checkbox" class="hidden peer"
                               :id="`AdminOfferCreateFilterCategory${i}`" name="category_id" :value="category.id"
                        />
                        <label :for="`AdminOfferCreateFilterCategory${i}`"
                               class="block w-full p-0.5 rounded-lg border-2 peer-checked:border-primary cursor-pointer">
                            {{ category.name }}
                        </label>
                    </li>
                </ul>

                <ul class="flex flex-row items-center gap-2">
                    <li v-for="(producer, i) in producers" :key="i">
                        <input v-model.number="filters.producer" type="checkbox" class="hidden peer"
                               :id="`AdminOfferCreateFilterProducer${i}`" name="producer_id" :value="producer.id"
                        />
                        <label :for="`AdminOfferCreateFilterProducer${i}`"
                               class="block w-full p-0.5 rounded-lg border-2 peer-checked:border-primary cursor-pointer">
                            {{ producer.name }}
                        </label>
                    </li>
                </ul>


                <ul class="grid grid-cols-3 gap-1">
                    <li v-for="(item, i) in items" :key="i">
                        <input v-model.number="data.items" type="checkbox" class="hidden peer"
                               :id="`AdminOfferCreateItem${i}`" name="item_id" :value="item.id"
                        />
                        <label :for="`AdminOfferCreateItem${i}`"
                               class="block w-full p-0.5 rounded-lg border-2 peer-checked:border-blue-600 cursor-pointer">
                            <img class="w-full h-auto rounded-md" :src="item.image" alt="">
                            <ul class="grid grid-cols-2 gap-1">
                                <li class="col-span-full">{{ item.name }}<br>{{ item.model }}</li>
                                <li>{{ item.producer.name }}</li>
                                <li class="text-right">{{ item.price }} ₴</li>
                            </ul>
                        </label>
                    </li>
                </ul>

            </div>

            <ul class="flex flex-col gap-4">

                <li>
                    <div class="flex flex-col gap-2">
                        <label for="AdminOffersCreateTitle">Заголовок</label>
                        <input v-model="data.title" id="AdminOffersCreateTitle" type="text"
                               class="input-normal input-md"/>
                    </div>
                </li>

                <li>
                    <div class="flex flex-col gap-2">
                        <label for="AdminOffersCreateSubtitle">Піздаголовок</label>
                        <input v-model="data.subtitle" id="AdminOffersCreateSubtitle" type="text"
                               class="input-normal input-md"/>
                    </div>
                </li>

                <li>
                    <div class="flex flex-col gap-2">
                        <label for="AdminOffersCreateButtonText">Текст кнопки</label>
                        <input v-model="data.button_text" id="AdminOffersCreateButtonText" type="text"
                               class="input-normal input-md"/>
                    </div>
                </li>

                <li>
                    <div class="flex flex-col gap-2">
                        <label for="AdminOffersCreateDiscount">Знижка (%)</label>
                        <input v-model="data.discount" v-maska data-maska="##" id="AdminOffersCreateDiscount"
                               type="number" min="1" max="100"
                               class="input-normal input-md"/>
                    </div>
                </li>

                <li class="col-span-full">
                    <div class="flex flex-col gap-2">
                        <label for="AdminOffersCreateButtonUrl">Url</label>
                        <input v-model="data.button_url" id="AdminOffersCreateButtonUrl" type="text"
                               class="input-normal input-md"/>
                    </div>
                </li>

                <li class="col-span-full">
                    <label>
                        <input v-model.number="data.dark" type="checkbox" name="dark" :value="1"/>
                        Чорний текст
                    </label>
                </li>

                <li class="col-span-full">
                    <div class="flex flex-col gap-2">
                        <label for="AdminOffersCreateDesc">Опис</label>
                        <ckeditor :editor="editor" v-model="data.desc" :config="editorConfig"></ckeditor>
                    </div>
                </li>

                <li class="col-span-full">
                    <div>
                        <input @change="previewImage" id="AdminOffersCreateImage" type="file" accept="image/*">
                        <img v-if="data.image || data.imagePath" :src="imageB64 ?? data.imagePath" alt="Preview" class="max-w-lg">
                    </div>
                </li>

            </ul>
        </div>
        <div class="flex justify-between items-center gap-2">
            <span> </span>
            <button v-show="!edit && !offerLoading.offer" @click.stop="createOffer(data)" type="submit"
                    class="btn-lg btn-primary">Створити
            </button>

            <button v-show="edit && !offerLoading.offer" @click.stop="updateOffer(data)" type="submit"
                    class="btn-lg btn-primary">Зберегти
            </button>
        </div>
    </div>
</template>

<script>
import {URL} from "../../config";
import {mapActions, mapState} from "pinia";
import {useItemStore, useOfferStore} from "../../stores";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {previewImage} from "../../helper"

export default {
    name: 'OfferCreate',

    data() {
        return {
            data: {
                title: null,
                subtitle: null,
                button_text: null,
                button_url: null,
                dark: false,
                discount: null,
                desc: '',
                image: null,
                imagePath: null,
                items: [],
            },

            filters: {
                producer: [],
                category: [],
                options: ['withoutOffer'],
            },

            selectAll: 0,

            imageB64: null,
            imagePath: null,

            editor: ClassicEditor,
            editorConfig: {
                filebrowserUploadUrl: '/offers/uploadImage',
                extraPlugins: [this.uploader],
            }
        }
    },

    computed: {
        ...mapState(useItemStore, {
            items: 'getItems',
            producers: 'getProducers',
            categories: 'getCategories',
        }),

        ...mapState(useOfferStore, {
            offer: 'getOffer',
            offerLoading: 'offerLoading',
        }),

        edit() {
            return parseInt(window.location.href.split('/').pop()) > 0
        },
    },

    methods: {
        previewImage,

        ...mapActions(useItemStore, [
            'fetchItems',
            'fetchProducers',
            'fetchCategories',
        ]),

        ...mapActions(useOfferStore, [
            'fetchOffer',
            'createOffer',
            'updateOffer',
        ]),

        uploader(editor) {
            editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                return new UploadAdapter(loader)
            }
        },

    },

    watch: {
        filters: {
            handler() {
                this.fetchItems(this.filters)
            },
            deep: true,
        },

        selectAll(newValue) {
            this.data.items = newValue ? this.items.map(i => i.id) : []
        },

        offer(newOffer) {
            if (this.edit && newOffer.title) {
                this.data = newOffer
                this.data.dark = newOffer.dark > 0
                this.data.items = newOffer.items.map(i => i.id)
                this.data.image = null
            }
        }
    },

    async created() {
        this.fetchItems(this.filters)
        this.fetchProducers()
        this.fetchCategories()
        if (this.edit) await this.fetchOffer(parseInt(window.location.href.split('/').pop()))
    },

}
</script>
