import {createApp} from 'vue'
import { createPinia } from 'pinia'
import { vMaska } from "maska"
import OrderCreate from './components/Order/OrderCreate.vue'

const orderCreate = createApp(OrderCreate)

if (document.getElementById('AdminOrderCreateApp')) {
    const pinia = createPinia()
    orderCreate.use(pinia)
    orderCreate.directive('maska', vMaska)
    window.orderCreate = orderCreate.mount('#AdminOrderCreateApp')
}
