const $ = require('jquery')

$('.btn-modal').on('click', function () {
    $('body').addClass('overflow-y-hidden')
    $($(this).data('modal-target')).fadeIn(400)
})

$('.modal').find('.modal-close').on('click', function () {
    $('body').removeClass('overflow-y-hidden')
    $(this).closest('.modal').fadeOut(400)
})

$(document).keydown(function (e) {
    if (document.keysPressed['Escape']) {
        $('body').removeClass('overflow-y-hidden')
        $('.modal').fadeOut(400)
    }

    if (document.keysPressed['n']) {
        $('body').addClass('overflow-y-hidden')
        $('main').find('.modal').first().fadeIn(400)
    }

    if (document.keysPressed['Control'] && document.keysPressed['k']) {
        e.preventDefault()
        $('body').addClass('overflow-y-hidden')
        $('#AdminSearchModal, #PublicSearchModal').fadeIn(400)
    }
})
