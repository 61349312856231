const $ = require('jquery');

$(document).on('click', '.dropdown', function (){
    const dropdownMenu = $(this).next('.dropdown-menu');
    $('.dropdown-menu').not(dropdownMenu).addClass('invisible');
    dropdownMenu.toggleClass('invisible');
});

$(document).on('click', function (e){
    if (!$(e.target).closest('.dropdown, .dropdown-menu').length) $('.dropdown-menu').addClass('invisible');
});

$(document).on('click', '.dropdown-menu', function(e) {
    e.stopPropagation();
});
