import {defineStore} from 'pinia'

import CatalogService from '../services/CatalogService'

export const useItemStore = defineStore('item', {
    state: () => ({
        items: [],
        producers: [],
        categories: [],

        options: [],
    }),

    getters: {
        getItems: (state) => state.items,
        getProducers: (state) => state.producers,
        getCategories: (state) => state.categories,

        getOptions: (state) => state.options,
    },

    actions: {
        async fetchItems(filters) {
            try {
                const res = await CatalogService.fetchItems(filters)
                this.items = res.data.data
            } catch (e) {

            }
        },

        async fetchProducers() {
            try {
                const res = await CatalogService.fetchProducers()
                this.producers = res.data.data
            } catch (e) {

            }
        },

        async fetchCategories() {
            try {
                const res = await CatalogService.fetchCategories()
                this.categories = res.data.data
            } catch (e) {

            }
        },

        async fetchOptions() {
            try {
                const res = await CatalogService.fetchOptions()
                this.options = res.data.data
            } catch (e) {

            }
        },
    },
})
