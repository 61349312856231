const $ = require('jquery')

document.keysPressed = {}

$(document).keydown(function (e) {
    document.keysPressed[e.key] = true
    console.log(document.keysPressed)
}).keyup(function (e) {
    document.keysPressed[e.key] = false
    console.log(document.keysPressed)
})
