<template>
    <div class="group relative">
        <div @click="toggle()" class=" py-2 flex justify-between space-x-2 cursor-pointer">
			<span v-if="price > 0">
				<i class="mdi mdi-cart"></i> {{ totalCount }}
			</span>

            <i v-else class="mdi mdi-cart-outline"></i>
        </div>

        <div v-if="visible"
             class="w-full h-full rounded-none fixed top-0 sm:w-96 sm:h-auto sm:rounded-xl md:top-full md:absolute right-0 shadow-lg text-gray-700 bg-white/70 backdrop-blur-sm overflow-hidden z-10">

            <div v-if="price > 0" class="cart-content">

                <div class="p-4 cart-head border-b border-dashed border-gray-300">
                    <div class="flex justify-between">
                        <span class="font-bold">Кошик</span>
                        <i @click="hide()" class="mdi mdi-close cursor-pointer"></i>
                    </div>
                </div>

                <div class="p-2 cart-body">
                    <ul class="w-full flex flex-col gap-y-2 h-96 overflow-y-auto">
                        <li v-for="item in items">
                            <ul class="w-full grid grid-cols-2 items-center gap-2">
                                <li class="col-span-full">
                                    <h5 class="font-semibold font-oswald">{{ item.name }}</h5>
                                </li>

                                <li>
                                    <img :src="item.image" alt="" class="w-full h-auto rounded-lg">
                                </li>

                                <li>
                                    <ul class="grid grid-cols-2 gap-2 whitespace-nowrap">
                                        <li class="col-span-full text-right">{{ item.total_price_display }}</li>

                                        <li>
                                            <i v-if="item.amount === 1"
                                               @click="decrement(item.id)"
                                               class="mdi mdi-minus p-2 text-neutral-300"></i>

                                            <i v-else
                                               @click="decrement(item.id)"
                                               class="mdi mdi-minus p-2 cursor-pointer"></i>

                                            <span class="p-2">{{ item.amount }}</span>

                                            <i v-if="item.amount < item.amount_available"
                                               @click="increment(item.id)"
                                               class="mdi mdi-plus p-2 cursor-pointer"></i>

                                            <i v-else
                                               class="mdi mdi-plus p-2 cursor-pointer text-neutral-300"></i>
                                        </li>

                                        <li class="text-right">
                                            <i @click="remove(item.id)"
                                               class="mdi mdi-trash-can-outline py-2 text-red-400 cursor-pointer"></i>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                        </li>
                    </ul>
                </div>

                <div class="p-2 cart-footer border-t border-dashed border-gray-300">
                    <ul class="mb-4 text-right text-xl">
                        <li>
                            <span class="font-bold font-oswald">ВСЬОГО: </span>
                            <span>{{ priceDisplay }}</span>
                        </li>
                    </ul>

                    <a href="/checkout" class="block btn-md btn-primary">ОФОРМИТИ ЗАМОВЛЕННЯ</a>
                </div>
            </div>

            <div v-else class="py-4 text-center text-neutral-300">
                Кошик пустий
                <p class="text-primary cursor-pointer" @click="hide()">Продовжити покупки</p>
            </div>

        </div>
    </div>

</template>

<script>
import {useCartStore} from "../../stores";
import {mapActions, mapState} from "pinia";

export default {

    data() {
        return {
            visible: false,
        }
    },

    computed: {
        ...mapState(useCartStore, {
            items: 'getCartItems',
            price: 'getCartPrice',
            priceDisplay: 'getCartPriceDisplay',
        }),

        totalCount: function () {
            return this.items.length
        },
    },

    methods: {
        ...mapActions(useCartStore, [
            'fetchCart',
            'add',
            'increment',
            'decrement',
            'remove',
            'clear',
        ]),

        toggle() {
            if (!this.visible) {
                this.fetchCart()
            }
            this.visible = !this.visible
        },

        show() {
            this.fetchCart()
            this.visible = true
        },

        hide() {
            this.visible = false
        },
    },

    created() {
        this.fetchCart()
    },
}
</script>
