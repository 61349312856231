<template>
    <div id="AdminTrendingCreateForm">
        <div class="grid grid-cols-2 gap-6 py-4">
            <div class="flex flex-col gap-2">
                <div class="flex flex-row justify-between items-center">
                    <h3 class="text-lg font-semibold">Фільтри</h3>
                </div>

                <h4 class="font-semibold">Категорії</h4>
                <ul class="flex flex-row flex-wrap items-center gap-2">
                    <li v-for="(category, i) in options.categories" :key="i">
                        <input v-model.number="data.filters.category" type="radio" class="hidden peer"
                               :id="`AdminTrendingCreateFilterCategory${i}`" name="category_id" :value="category.slug"
                        />
                        <label :for="`AdminTrendingCreateFilterCategory${i}`"
                               class="block w-full p-1 rounded-lg border-2 peer-checked:border-primary cursor-pointer">
                            {{ category.name }}
                        </label>
                    </li>
                </ul>

                <h4 class="font-semibold">Виробники</h4>
                <ul class="flex flex-row flex-wrap items-center gap-2">
                    <li v-for="(producer, i) in options.producers" :key="i">
                        <input v-model.number="data.filters.producer" type="checkbox" class="hidden peer"
                               :id="`AdminTrendingCreateFilterProducer${i}`" name="producer_id" :value="producer.id"
                        />
                        <label :for="`AdminTrendingCreateFilterProducer${i}`"
                               class="block w-full p-1 rounded-lg border-2 peer-checked:border-primary cursor-pointer">
                            {{ producer.name }}
                        </label>
                    </li>
                </ul>

                <h4 class="font-semibold">Атрибути</h4>
                <ul class="flex flex-col gap-2">
                    <li v-for="(attribute, key) in options.attributes" :key="key">
                        <small class="font-semibold">{{ attribute[0].attribute_name }}</small>
                        <ul class="flex flex-row flex-wrap items-center gap-2">
                            <li v-for="(attr, i) in attribute" :key="i">
                                <input v-model="data.filters.attribute[key]" type="checkbox" class="hidden peer"
                                       :id="`AdminTrendingCreateFilterAttribute${key}${i}`" :name="key"
                                       :true-value="[]"
                                       :value="attr.value"
                                />
                                <label :for="`AdminTrendingCreateFilterAttribute${key}${i}`"
                                       class="block w-full p-1 rounded-lg border-2 peer-checked:border-primary cursor-pointer">
                                    {{ attr.value }}
                                </label>
                            </li>
                        </ul>
                    </li>
                </ul>

            </div>

            <ul class="grid grid-cols-2 gap-4">
                <li class="col-span-full">
                    <div class="flex flex-col gap-2">
                        <label for="AdminTrendingCreateTitle">Заголовок</label>
                        <input v-model="data.title" id="AdminTrendingCreateTitle" type="text"
                               class="input-normal input-md"/>
                    </div>
                </li>

                <li class="col-span-full">
                    <div class="flex flex-col gap-2">
                        <label for="AdminTrendingCreateSubtitle">Підзаголовок</label>
                        <input v-model="data.subtitle" id="AdminTrendingCreateSubtitle" type="text"
                               class="input-normal input-md"/>
                    </div>
                </li>

                <li>
                    <div class="flex flex-col gap-2">
                        <label for="AdminTrendingCreateButtonText">Текст кнопки</label>
                        <input v-model="data.button_text" id="AdminTrendingCreateButtonText" type="text"
                               class="input-normal input-md"/>
                    </div>
                </li>

                <li class="py-8">
                    <label>
                        <input v-model.number="data.dark" type="checkbox" name="dark" :value="1"/>
                        Чорний текст
                    </label>
                </li>

                <li class="col-span-full">
                    <div>
                        <input @change="previewImage" id="AdminTrendingCreateImage" type="file" accept="image/*">
                        <img v-if="data.image || imagePath" :src="imageB64 ?? imagePath" alt="Preview" class="max-w-lg">
                    </div>
                </li>

            </ul>
        </div>
        <div class="flex justify-between items-center gap-2">
            <span> </span>
            <button v-show="!edit && !trendingLoading.trending" @click.stop="createTrending(data)" type="submit"
                    class="btn-lg btn-primary">Створити
            </button>

            <button v-show="edit && !trendingLoading.trending" @click.stop="updateTrending(data)" type="submit"
                    class="btn-lg btn-primary">Зберегти
            </button>
        </div>
    </div>
</template>

<script>
import {URL} from "../../config";
import {mapActions, mapState} from "pinia";
import {useItemStore, useTrendingStore} from "../../stores";
import {previewImage} from "../../helper"

export default {
    name: 'TrendingCreate',

    data() {
        return {
            data: {
                title: null,
                subtitle: null,
                button_text: null,
                dark: false,
                image: null,
                filters: {
                    category: '',
                    producer: [],
                    attribute: {},
                },
            },

            imageB64: null,
            imagePath: null,

        }
    },

    computed: {
        ...mapState(useItemStore, {
            options: 'getOptions',
        }),

        ...mapState(useTrendingStore, {
            trending: 'getTrending',
            trendingLoading: 'trendingLoading',
        }),

        edit() {
            return parseInt(window.location.href.split('/').pop()) > 0
        },
    },

    methods: {
        previewImage,

        ...mapActions(useItemStore, [
            'fetchOptions',
        ]),

        ...mapActions(useTrendingStore, [
            'fetchTrending',
            'createTrending',
            'updateTrending',
        ]),

        getTrendingImagePath(image) {
            return `${URL}/storage/catalog/trending/${image}`
        },

    },

    watch: {
        options(newOptions) {
            this.data.filters.attribute = Object.fromEntries(Object.keys(newOptions.attributes).map(key => [key, []]))
        },

        trending(newTrending) {
            if (this.edit && newTrending.title) {

                setTimeout(() => {
                    this.data.id = newTrending.id
                    this.data.dark = newTrending.dark > 0
                    this.data.title = newTrending.title
                    this.data.subtitle = newTrending.subtitle
                    this.data.button_text = newTrending.button_text
                    this.data.filters = newTrending.button_url
                    this.data.filters.attribute = newTrending.button_url.attribute ?? {}
                    this.imagePath = this.getTrendingImagePath(newTrending.image)
                    this.data.image = null
                }, 1000)

            }
        }
    },

    async created() {
        this.fetchOptions()
        if (this.edit) await this.fetchTrending(parseInt(window.location.href.split('/').pop()))
    },

}
</script>
