import RequestProvider from '../providers/ApiRequestProvider'

const URL_PART = 'cart'

export default {
    fetchCart() {
        return RequestProvider._get(`${URL_PART}`)
    },

    add(id) {
        return RequestProvider._post(`${URL_PART}`, {id, amount: 1})
    },

    increment(id) {
        return RequestProvider._put(`${URL_PART}/increment`, {id})
    },

    decrement(id) {
        return RequestProvider._put(`${URL_PART}/decrement`, {id})
    },

    remove(id) {
        return RequestProvider._delete(`${URL_PART}/remove`, {id})
    },

    clear() {
        return RequestProvider._delete(`${URL_PART}`)
    },

}
