import {createApp} from 'vue'
import {createPinia} from 'pinia'
import {vMaska} from "maska"
import CKEditor from '@ckeditor/ckeditor5-vue';
import OfferCreate from './components/Offer/OfferCreate.vue'

const offerCreate = createApp(OfferCreate)

if (document.getElementById('AdminOfferCreateApp')) {
    const pinia = createPinia()
    offerCreate.use(CKEditor)
    offerCreate.use(pinia)
    offerCreate.directive('maska', vMaska)
    window.offerCreate = offerCreate.mount('#AdminOfferCreateApp')
}
