import ApiRequestProvider from '../providers/ApiRequestProvider'
import {objectToQueryParams} from "../helper";

const PREFIX = 'catalog'

const addFilters = (formData, filters) => {
    Object.keys(filters).forEach(key => {

        if (Array.isArray(filters[key])) {
            filters[key].forEach((k, i) => {
                formData.append(`filters[${key}][]`, filters[key][i])
            })
        } else if (typeof filters[key] === 'object') {
            Object.keys(filters[key]).forEach(k => {
                filters[key][k].forEach(v => {
                    formData.append(`filters[${key}][${k}][]`, v)
                })

            })
        } else {
            formData.append(`filters[${key}]`, filters[key])
        }
    })
}

export default {
    async fetchItems(filters = {}) {
        return await ApiRequestProvider._get(`${PREFIX}/items?${objectToQueryParams(filters).toString()}`)
    },

    async fetchProducers() {
        return await ApiRequestProvider._get(`${PREFIX}/producers`)
    },

    async fetchCategories() {
        return await ApiRequestProvider._get(`${PREFIX}/categories`)
    },

    async fetchAttributes() {
        return await ApiRequestProvider._get(`${PREFIX}/attributes`)
    },

    async fetchOptions() {
        return await ApiRequestProvider._get(`${PREFIX}/options`)
    },

    async fetchTrending(id) {
        return await ApiRequestProvider._get(`${PREFIX}/trending/${id}`)
    },

    async createTrending(trending) {
        const formData = new FormData

        formData.append(`title`, trending.title ?? '')
        formData.append(`subtitle`, trending.subtitle ?? '')
        formData.append(`button_text`, trending.button_text ?? '')
        formData.append(`dark`, trending.dark ? 1 : 0)
        formData.append(`image`, trending.image)
        addFilters(formData, trending.filters)

        return await ApiRequestProvider._post(
            `${PREFIX}/trending`,
            formData,
            {'Content-Type': 'multipart/form-data'}
        )
    },

    async updateTrending(trending) {
        const formData = new FormData

        formData.append(`_method`, 'PUT')
        formData.append(`title`, trending.title ?? '')
        formData.append(`subtitle`, trending.subtitle ?? '')
        formData.append(`button_text`, trending.button_text ?? '')
        formData.append(`dark`, trending.dark ? 1 : 0)
        formData.append(`image`, trending.image)
        addFilters(formData, trending.filters)

        return await ApiRequestProvider._post(
            `${PREFIX}/trending/${trending.id}`,
            formData,
            {'Content-Type': 'multipart/form-data'}
        )
    },
}
