const $ = require('jquery');

$('.form-image-input').on('change', function () {

    const target = $(this).prev('.form-image-preview')
    $(target).html('');

    [...this.files].forEach(file => {
        const reader = new FileReader()
        reader.onload = function (e) {
            $(target).append(
                $('<img>').addClass('w-full rounded-xl aspect-auto').attr('src', e.target.result)
            )
        }

        reader.readAsDataURL(file)
    })

})
