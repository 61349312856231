module.exports = {
    previewImage(event) {
        if (!event.target.files[0]) return;

        this.data.image = event.target.files[0];

        const reader = new FileReader();
        reader.onload = (e) => {
            this.imageB64 = e.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
    },

    objectToQueryParams(obj) {
        const params = new URLSearchParams();

        Object.keys(obj).forEach((key) => {
            if (Array.isArray(obj[key])) {
                obj[key].forEach((value) => {
                    params.append(`${key}[]`, value);
                });
            } else {
                params.append(key, obj[key]);
            }
        });

        return params;
    },
}
